h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

html,
body,
#root {
  height: 100%;
}

.ant-input-number {
  width: 100%;
}

.white-content {
  background-color: #fff;
  padding: 1em;
}

form.narrow-form .ant-row.ant-form-item {
  margin-bottom: 6px;
}

form.narrow-form .ant-form-item-label {
  line-height: 24px;
}
